import React, { createContext, useReducer } from 'react';

export const UiContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'showMenu':
      return {
        ...state,
        menuOpen: true
      };
    case 'hideMenu':
      return {
        ...state,
        menuOpen: false
      };
    default:
      throw new Error();
  }
};

// write a custom provider that wraps around the initial provider that
// exposes the get and set functions
function UiContextProvider(props) {
  // make some state
  const [state, dispatch] = useReducer(reducer, {
    menuOpen: false
  });

  // expose to provider
  const value = [state, dispatch];

  return (
    <UiContext.Provider value={value}>{props.children}</UiContext.Provider>
  );
}

export default UiContextProvider;
