import React, { Component } from 'react';
import styled from 'styled-components';

const SlidesWrapper = styled.div`
  position: absolute;
  z-index: ${p => p.theme.z.body};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdc3c7;
`;

const Slide = styled.div`
  z-index: ${p => (p.active ? p.theme.z.body + 1 : p.theme.z.body)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${p => p.poster});
  background-size: cover;
  background-position: 50% 50%;
  opacity: ${p => (p.active ? 1 : 0)};
  transition: opacity 0.5s;
  transition-delay: ${p => (p.active ? '0' : '0.5s')};
  cursor: pointer;
`;

class Slideshow extends Component {
  state = {
    slideIndex: 0
  };

  timeout = undefined;

  autoNext = () => {
    const { delay = 5000 } = this.props;

    this.timeout = setTimeout(() => {
      this.next();
    }, delay);
  };

  next = () => {
    // console.log('GOTO next');
    const { slides, setSelectedSlide } = this.props;
    const { slideIndex } = this.state;

    window.clearTimeout(this.timeout);
    const slide = slideIndex < slides.length - 1 ? slideIndex + 1 : 0;
    this.setState({ slideIndex: slide });
    this.autoNext();
    setSelectedSlide(slides[slide]);
  };

  prev = () => {
    // console.log('GOTO prev');
    const { slides, setSelectedSlide } = this.props;
    const { slideIndex } = this.state;

    window.clearTimeout(this.timeout);
    const slide = slideIndex > 0 ? slideIndex - 1 : slides.length - 1;
    this.setState({ slideIndex: slide });
    this.autoNext();
    setSelectedSlide(slides[slide]);
  };

  componentDidMount() {
    this.autoNext();
    this.props.registerFunc('next', this.next);
    this.props.registerFunc('prev', this.prev);
    this.props.setSelectedSlide(this.props.slides[this.state.slideIndex]);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  render() {
    const { slides } = this.props;
    const { slideIndex } = this.state;

    // use title instead
    // onClick={() => this.props.gotoVideo(slides[i].uri)}
    // too many element in front of slideshow!

    return (
      <SlidesWrapper>
        {slides.map((slide, i) => (
          <Slide key={i} poster={slides[i].poster} active={slideIndex === i} />
        ))}
      </SlidesWrapper>
    );
  }
}

export default Slideshow;
