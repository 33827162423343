import styled from 'styled-components';

export const Container = styled.div`
/*
  height: 100% !important;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${p => p.theme.z.body};
  */

  min-height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${p => p.theme.z.body};

`;

export const Content = styled.div`
  flex: 1;
  padding-top: 3.75rem; /* 60px */
`;

export const PageTitle = styled.h2`
  font-size: 1.375rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: ${p => (p.noSpacing ? 0 : '3.125rem')}; /* 50px */
`;

export const TextCol = styled.div`
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 200;
  margin-bottom: 3.125rem;
  a {
    color: ${p => p.theme.colors.dark};
    text-decoration: none;
    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }
`;

export const TextTitle = styled.div`
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const VideosCols = styled.div`
  margin-bottom: 3.125rem; // 50px
`;
