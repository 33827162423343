import React from 'react';
import styled from 'styled-components';

const Arrow = styled(({ className, onClick = () => {} }) => (
  <svg className={className} onClick={onClick} viewBox="0 0 10 24">
    <path
      d="M8.5 1.5 L1.5 12 L8.5 22.5"
      strokeWidth="3"
      strokeLinecap="round"
      fill="transparent"
    />
  </svg>
))`
  cursor: pointer;
  width: 10px;
  height: 24px;
  cursor: pointer;
  padding: 0.6rem;
  box-sizing: content-box;
  &:hover,
  &:active {
    opacity: 0.6;
  }
  path {
    stroke: ${p => (p.dark ? p.theme.colors.dark : p.theme.colors.light)};
  }
  /*
  transform: ${p =>
    p.right ? 'rotate(180deg) translateX(-2.6rem)' : 'translateX(-2.6rem)'};
    */
`;

export default Arrow;
