import React, { useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// contextx
import { UiContext } from 'contexts/UiContext';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: ${p => p.right || 0}px;
  bottom: 0;
  background-color: ${p => p.theme.colors.light};
  z-index: ${p => p.theme.z.menu};
  opacity: ${p => (p.visible ? '1.0' : '0')};
  pointer-events: ${p => (p.visible ? 'all' : 'none')};
  transition: opacity 0.5s;
`;

const MenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => p.theme.z.menu + 1};
`;

const Container = styled.div`
  height: 100% !important;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${p => p.theme.z.menu + 2};
  /* pointer-events: none; */
  > div {
    z-index: ${p => p.theme.z.menu + 2};
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.375rem;
  pointer-events: none;
  a {
    pointer-events: ${p => (p.visible ? 'all' : 'none')};

    color: black;
    text-decoration: none;
    margin-bottom: 0.75rem;
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
`;

function Menu({ scrollBarWidth = 0 }) {
  const [ui, uiDispatch] = useContext(UiContext);

  function closeMenu(e) {
    uiDispatch({ type: 'hideMenu' });
  }

  // set body overflow
  // fucks up :/

  useLayoutEffect(
    () => {
      // when menu changes
      // force scroll, but make

      document.body.style.overflowY = ui.menuOpen ? 'hidden' : 'auto';

      /*
      if (ui.menuOpen === true) {
        document.getElementById('root').style.height = '100%';
        document.getElementById('root').style.height = '100%';
      } else {
        // document.getElementById('root').style.height = 'auto';
      }
      */

      return () => {
        document.body.style.overflowY = 'auto';
        //document.getElementById('root').style.height = 'auto';
      };
    },
    [ui.menuOpen]
  );

  return (
    <MenuContainer right={-scrollBarWidth} visible={ui.menuOpen}>
      <Container className="container">
        <MenuBackdrop onClick={closeMenu} />
        <Header dark menuOpen={ui.menuOpen} />
        <Content visible={ui.menuOpen}>
          <Link onClick={closeMenu} to="/commercials">
            Commercials
          </Link>
          <Link onClick={closeMenu} to="/fiction">
            Fiction
          </Link>
          <Link onClick={closeMenu} to="/about">
            About
          </Link>
        </Content>
        <Footer dark />
      </Container>
    </MenuContainer>
  );
}

export default Menu;
