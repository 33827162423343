import React from 'react';
import { Route } from 'react-router-dom';

// page components
import LandingPage from './pages/LandingPage';
import TextPage from './pages/TextPage';
import VideosPage from './pages/VideosPage';
import VideoPage from './pages/VideoPage';
import NotFoundPage from './pages/NotFoundPage';

function Routes() {
  return (
    <>
      <Route path="/" exact component={LandingPage} />
      <Route
        path="/:page"
        exact
        render={({ match }) => {
          switch (match.params.page) {
            case 'about':
              return <TextPage page={match.params.page} />;
            case 'commercials':
            case 'fiction':
              return <VideosPage page={match.params.page} />;
            default:
              return <NotFoundPage />;
            //throw new Error();
          }
        }}
      />
      <Route
        path="/:page/:video"
        exact
        render={({ match }) => <VideoPage video={match.params.video} />}
      />
    </>
  );
}

export default Routes;
