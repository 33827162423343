import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// context
import UiContextProvider from 'contexts/UiContext';
import DataContext from 'contexts/DataContext';

// styles
import 'normalize.css';
import 'assets/styles/layout.scss';
import 'assets/styles/global.scss';
import theme from 'assets/styles/theme.js';

console.log('v4');

// supress console log in production
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

//console.log('🎨 theme', theme);

// load data?
fetch('https://christianbagger.com/wp/wp-json/bagger/v3/data')
  .then(res => res.json())
  .then(data => {
    //console.log('📹 DATA', data);
    ReactDOM.render(
      <DataContext.Provider value={data}>
        <ThemeProvider theme={theme}>
          <UiContextProvider>
            <Router>
              <App />
            </Router>
          </UiContextProvider>
        </ThemeProvider>
      </DataContext.Provider>,
      document.getElementById('root')
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
