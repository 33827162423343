import React, { useContext } from 'react';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

import DataContext from 'contexts/DataContext';

// hooks
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';

import {
  Container,
  Content,
  PageTitle,
  TextCol,
  TextTitle
} from 'assets/styles/styles.js';

import NotFoundPage from 'pages/NotFoundPage';

function TextPage(props) {
  const { pages } = useContext(DataContext);
  const page = pages[props.page];

  if (page === undefined) {
    return <NotFoundPage />;
  }

  useDocumentTitle(page.page_title);
  useDocumentMeta('description', page.meta_description || '');

  return (
    <Container className="container">
      <Header dark />
      <Content>
        {page.page_title && (
          <div className="columns">
            <TextCol className="column col-6">
              <PageTitle noSpacing>{page.page_title}</PageTitle>
            </TextCol>
          </div>
        )}

        <div className="columns">
          <TextCol className="column col-6 col-sm-12">
            {page.column_1_title && (
              <TextTitle>
                {page.column_1_title}
                <br />—
              </TextTitle>
            )}
            {page.column_1_content && (
              <div
                dangerouslySetInnerHTML={{ __html: page.column_1_content }}
              />
            )}
          </TextCol>
          <TextCol className="column col-3 col-md-1 hide-sm" />
          <TextCol className="column col-3 col-md-5 col-sm-12">
            {page.column_2_title && (
              <TextTitle>
                {page.column_2_title}
                <br />—
              </TextTitle>
            )}
            {page.column_2_content && (
              <div
                dangerouslySetInnerHTML={{ __html: page.column_2_content }}
              />
            )}
          </TextCol>
        </div>
      </Content>
      <Footer dark />
    </Container>
  );
}

export default TextPage;
