/**
 * A drop-in component to listen for resizes or scroll-bar show/hide
 *
 * Based on https://gist.github.com/OrganicPanda/8222636
 */

import React, { Component } from 'react';

class ScrollBarAdapter extends Component {
  onResize = () => {
    if (this.props.onResize) {
      this.props.onResize();
      return;
    }
    try {
      var evt = new UIEvent('resize');
      window.dispatchEvent(evt);
    } catch (e) {}
  };

  componentDidMount() {
    this.refs.frame.contentWindow.addEventListener(
      'resize',
      this.onResize,
      false
    );
  }
  componentWillUnmount() {
    this.refs.frame.contentWindow.removeEventListener('resize', this.onResize);
  }

  render() {
    var styles = {
      height: 0,
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      borderWidth: 0,
      position: 'absolute',
      backgroundColor: 'transparent',
      width: '100%',
      pointerEvents: 'none'
    };
    return (
      <iframe
        title="ScrollBarAdapter"
        className="ScrollBarAdapter"
        ref="frame"
        style={styles}
      />
    );
  }
}

export default ScrollBarAdapter;
