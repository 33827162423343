import { useEffect } from 'react';

function decodeHtml(html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

function useDocumentTitle(title = '', prepend = true) {
  useEffect(() => {
    let finalTitle = decodeHtml(title);
    if (title !== '' && prepend) finalTitle += ' | ';
    if (prepend) finalTitle += 'Christian Bagger';
    if (title === '') finalTitle += ' | Director';
    document.title = finalTitle;
  });
}

export default useDocumentTitle;
