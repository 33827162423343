import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// contextx
import { UiContext } from 'contexts/UiContext';

const Top = styled.div`
  padding-top: 3.75rem; // 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.dark ? p.theme.colors.dark : p.theme.colors.light)};
  svg > path {
    stroke: ${p => (p.dark ? p.theme.colors.dark : p.theme.colors.light)};
  }
`;

const Title = styled.div`
  font-size: 1.125rem; /* 18px */
  color: inherit;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
      text-decoration: none;
      > div {
        opacity: 0.6;
      }
    }
  }
  > a > div:nth-child(1) {
    font-size: 400;
    margin-bottom: 0.4rem;
  }
  > a > div:nth-child(2) {
    font-weight: 200;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const MenuIcon = styled(({ className, onClick }) => (
  <svg className={className} viewBox="0 0 32 24" onClick={onClick}>
    <path d="M6 1.5 L26 1.5" strokeWidth="3" strokeLinecap="round" />
    <path d="M2 11.5 L30 11.5" strokeWidth="3" strokeLinecap="round" />
    <path d="M6 21.5 L26 21.5" strokeWidth="3" strokeLinecap="round" />
  </svg>
))`
  cursor: pointer;
  width: 32px;
  height: 24px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.6;
  }
  path {
    stroke: ${p => p.theme.colors.light};
  }
  path:nth-child(1) {
    display: ${p => (p.menuOpen ? 'none' : 'block')};
  }
  path:nth-child(3) {
    display: ${p => (p.menuOpen ? 'none' : 'block')};
  }
  padding: 1rem;
  transform: translateX(1rem);
  box-sizing: content-box;
`;

function Header({ dark = false, menuOpen = false }) {
  const [ui, uiDispatch] = useContext(UiContext);

  function handleLogoClick() {
    uiDispatch({ type: 'hideMenu' });
  }

  function handleMenuClick() {
    // find a more elegant way?
    function callback() {
      const type = ui.menuOpen ? 'hideMenu' : 'showMenu';
      uiDispatch({ type });
    }

    // SCROLL TO TOP
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
      if (c <= 0) callback();
    };
    if (scrollTop > 0) {
      scrollToTop();
    } else {
      callback();
    }
  }

  return (
    <Top dark={dark}>
      <Title>
        <Link onClick={handleLogoClick} to="/">
          <div>Christian Bagger</div>
          <div>Director</div>
        </Link>
      </Title>
      <Spacer />
      <MenuIcon onClick={handleMenuClick} menuOpen={menuOpen} />
    </Top>
  );
}

export default Header;
