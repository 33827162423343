import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';

import DataContext from 'contexts/DataContext';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Arrow from 'components/Arrow';

import Slideshow from 'components/Slideshow';

// Assets
// import bgImg1 from 'assets/window-master.png';
// import bgImg2 from 'assets/posters/Zetland_still-min.jpg';
// import bgImg3 from 'assets/posters/vhv-rockfan-min.jpg';

import { Container } from 'assets/styles/styles.js';

// const Container = styled.div`
//   /* height: 100% !important; */
//   min-height: 100%;
//   position: absolute;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   z-index: ${p => p.theme.z.body};
// `;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg:nth-child(1) {
    transform: translateX(-2.6rem);
    @media (max-width: 700px) {
      transform: translateX(0);
    }
  }
  > svg:nth-child(2) {
    transform: rotate(180deg) translateX(-2.6rem);
    @media (max-width: 700px) {
      transform: rotate(180deg) translateX(0);
    }
  }
`;

function Landing() {
  const { pages, videos } = useContext(DataContext);
  const page = pages['landing'];
  //const landingVideos = videos.filter(video => in)

  // make array of slides from videos
  const slides = page.videos.reduce((arr, videoName) => {
    // video needs a parent to be shown on the landing page
    // else we cant link to it...
    if (videos[videoName].parent === undefined) {
      return arr;
    }
    arr.push({
      uri: videoName,
      title: videos[videoName].video_title,
      poster: videos[videoName].poster,
      poster_preload: videos[videoName].poster_preload,
      parent: videos[videoName].parent
    });
    return arr;
  }, []);

  // console.log(slides);

  const [selectedSlide, setSelectedSlide] = useState();

  // lets attempt to preload images?
  const [ready, setReady] = useState(false);

  // okay, a lil dirty trick...
  // since hooks are funky with timeout and intervals, we make Slideshow an
  // oldschool class component.
  // BUT the prev next buttons are still in this component, b/c of grid.
  // so the Slideshow can "register" functions in "funcs" array, so this
  // component can use them :3
  const funcs = useRef({});
  function registerFunc(name, func) {
    funcs.current[name] = func;
  }
  function useFunc(name) {
    if (funcs.current[name] !== undefined) funcs.current[name]();
  }

  return (
    <>
      <Slideshow
        slides={slides}
        registerFunc={registerFunc}
        setSelectedSlide={setSelectedSlide}
      />
      <Container className="container">
        <Header />
        <Content>
          {slides.length > 1 && (
            <>
              <Arrow left onClick={() => useFunc('prev')} />
              <Arrow right onClick={() => useFunc('next')} />
            </>
          )}
        </Content>
        <Footer slide={selectedSlide} />
      </Container>
    </>
  );
}

export default Landing;
