import React, { useContext } from 'react';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

// hooks
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';

import {
  Container,
  Content,
  PageTitle,
  TextCol
} from 'assets/styles/styles.js';

function TextPage(props) {
  useDocumentTitle('Page not found');
  useDocumentMeta('description', '');

  return (
    <Container className="container">
      <Header dark />
      <Content>
        <div className="columns">
          <TextCol className="column col-6">
            <PageTitle noSpacing>Page not found</PageTitle>
          </TextCol>
        </div>
      </Content>
      <Footer dark />
    </Container>
  );
}

export default TextPage;
