import detectIt from 'detect-it';
import Bowser from 'bowser';

function getScrollbarWidth() {
  var outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  var inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const browserVersion = parseInt(browser.getBrowserVersion());

const theme = {
  touch: detectIt.deviceType === 'touchOnly',
  scrollbarWidth: getScrollbarWidth(),
  edgeFix: browserName === 'Microsoft Edge' && browserVersion <= 17,
  colors: {
    light: '#F0F0F0',
    dark: '#111111'
  },
  z: {
    menu: 1000,
    body: 100,
    ui: 200
  }
};

export default theme;
