import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import DataContext from 'contexts/DataContext';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';

// hooks
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';

// Assets
// import poster1 from 'assets/posters/Zetland_still-min.jpg';
// import poster2 from 'assets/posters/vores-min.jpg';
// import poster3 from 'assets/posters/vhv-rockfan-min.jpg';
// import poster4 from 'assets/posters/flugger-2-min.jpg';
// import poster5 from 'assets/posters/matas-min.jpg';
// import poster6 from 'assets/posters/Screen-Shot-2018-02-27-at-14.44.49-min.jpg';

import {
  Container,
  Content,
  PageTitle,
  VideosCols
} from 'assets/styles/styles.js';

const VideoThumb = styled(({ className, title }) => (
  <div className={className}>
    <div>
      <div>
        <h3 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    </div>
  </div>
))`
  width: 100%;
  height: 0;
  padding-bottom: calc(9 / 16 * 100%);
  position: relative;
  margin-bottom: 0.5rem; /* 2 x $layout-spacing */
  cursor: pointer;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    background-image: url(${p => p.poster});
    background-size: cover;
    background-position: 50% 50%;

    > div {
      opacity: ${p => (p.theme.touch ? '1' : '0')};
      transition: opacity 0.4s;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      > h3 {
        color: ${p => p.theme.colors.light};
        font-size: 1.125rem; /* 18px */
        font-weight: 400;
      }
    }
  }
  &:hover {
    > div > div {
      opacity: 1;
    }
  }
`;

function VideosPage(props) {
  const { pages, videos } = useContext(DataContext);
  const page = pages[props.page];

  useDocumentTitle(page.page_title);
  useDocumentMeta('description', page.meta_description || '');

  return (
    <Container className="container">
      <Header dark />
      <Content>
        {page.page_title && (
          <div className="columns">
            <div className="column col-12">
              <PageTitle>{page.page_title}</PageTitle>
            </div>
          </div>
        )}

        <VideosCols className="columns">
          {page.videos.map(videoName => {
            const video = videos[videoName];
            if (video.external_link !== undefined) {
              return (
                <div
                  key={videoName}
                  className="column col-4 col-md-6 col-sm-12"
                >
                  <a
                    href={video.external_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VideoThumb
                      poster={video.thumb}
                      title={video.video_title}
                    />
                  </a>
                </div>
              );
            } else {
              return (
                <div
                  key={videoName}
                  className="column col-4 col-md-6 col-sm-12"
                >
                  <Link to={`${props.page}/${videoName}`}>
                    <VideoThumb
                      poster={video.thumb}
                      title={video.video_title}
                    />
                  </Link>
                </div>
              );
            }
          })}
        </VideosCols>
      </Content>
      <Footer dark />
    </Container>
  );
}

export default VideosPage;
