import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Player from '@vimeo/player';
import useReactRouter from 'use-react-router';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Arrow from 'components/Arrow';

import DataContext from 'contexts/DataContext';

import useDocumentTitle from 'hooks/useDocumentTitle';
import useDocumentMeta from 'hooks/useDocumentMeta';

// import poster1 from 'assets/posters/Zetland_still-min.jpg';

import {
  Container,
  Content,
  PageTitle,
  TextCol,
  TextTitle
} from 'assets/styles/styles.js';

import NotFoundPage from 'pages/NotFoundPage';

const VideoPoster = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: calc(9 / 16 * 100%);
  position: relative;
  margin-bottom: 3.75rem;
  background-color: ${p => p.theme.colors.dark};
  cursor: pointer;

  /* the video poster */
  > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${p => p.poster});
    background-size: cover;
    background-position: 50% 50%;
    z-index: ${p => p.theme.z.body};
    transition: opacity 0.5s;
    pointer-events: none;
  }

  /* vimeo video */
  /*> iframe:nth-child(2) {*/
  > iframe {
    background-color: black;
    z-index: ${p => p.theme.z.body + 2};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: ${p => (p.playing === 'true' || p.theme.edgeFix ? 1 : 0.01)};
    transition: opacity 0.5s;
    cursor: pointer;
    pointer-events: all;
  }

  /* the play button */
  > div:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${p => p.theme.z.body + 1};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: ${p => (p.theme.touch ? '1' : '0')};
    transition: opacity 0.5s;
    pointer-events: none;
    > div {
      font-size: 1.375rem;
      color: ${p => p.theme.colors.light};
      font-weight: 200;
    }
  }

  /*
  > iframe:nth-child(2):hover + div {
    opacity: 1;
  }
  */

  &:hover {
    > div:nth-child(3) {
      opacity: 1;
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;
`;

const ArrowsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg:nth-child(1) {
    transform: translateX(-2.6rem);
  }
  > svg:nth-child(2) {
    transform: rotate(180deg) translateX(-2.6rem);
  }
  @media (max-width: 700px) {
    > svg {
      display: none;
    }
  }
`;

/*
const isValidBrowser = browser.satisfies({
  // declare browsers per OS
  windows: {
    msedge: '<=42'
  }
});
*/

//console.log('isValidBrowser', isValidBrowser);

function VideoPage(props) {
  const { match, history, location } = useReactRouter();

  const { pages, videos } = useContext(DataContext);
  const video = videos[props.video];

  if (video === undefined) {
    return <NotFoundPage />;
  }

  // console.log('VIDEO', props.video);

  const parentPage = pages[match.params.page];

  //console.log('VS1', parentPage.videos);

  // make new array of video, that are NOT links
  const parentPageVideos = parentPage.videos.filter(parentPageVideo => {
    return videos[parentPageVideo].external_link === undefined;
  });

  //console.log('VS2', parentPageVideos);

  // attempt to figure out what video we are, so we can do the prev / next buttons
  const pageVideoIndex = parentPageVideos.indexOf(video.video_name);
  const pageVideoCount = parentPageVideos.length;

  useDocumentTitle(`${video.video_title}`);
  useDocumentMeta('description', video.meta_description || '');

  // IF VIDEO IS UNDEF, GOTO LANDING?
  // OR 404?

  // console.log('📹 VIDEO', video);

  const player = useRef(); // https://developer.vimeo.com/player/sdk/reference
  const iframe = useRef();

  const [playing, setPlaying] = useState(false);

  // set to not playing when chaning page
  /*
  useEffect(
    () => {
      setPlaying(false);
    },
    [location.pathname]
  );
  */

  function handleVideoClick() {
    console.log('📹 CLICKED ON VIDEO');
    // to to force it?
    /*
    // this will trigger the "not interacted" error...
    // + only works in chrome!!
    if (playing === true) return;
    setPlaying(true);
    player.current.play();
    */
  }

  useEffect(() => {
    player.current = new Player(iframe.current);

    player.current.on('bufferstart', () => {
      //console.log('📹 BUFFER START', playing);
      if (playing === false) setPlaying(true);
    });

    player.current.on('play', () => {
      //console.log('📹 PLAY', playing);
      if (playing === false) setPlaying(true);
    });
  }, []);

  useEffect(
    () => {
      //console.log('LOAD NEW VID!!', video);
      setPlaying(false);
      player.current.loadVideo(video.vimeo_id);
    },
    [video]
    //[]
  );

  /*
  useEffect(
    () => {
      console.log('LOAD NEW VID!!', video);

      setPlaying(false);

      iframe.current.src = '';
      iframe.current.src = `https://player.vimeo.com/video/${video.vimeo_id}`;

      player.current = new Player(iframe.current);

      player.current.on('bufferstart', () => {
        console.log('📹 BUFFER START', playing);
        if (playing === false) setPlaying(true);
      });

      player.current.on('play', () => {
        console.log('📹 PLAY', playing);
        if (playing === false) setPlaying(true);
      });
      // player.current.on('pause', function() {});
      // player.current.on('ended', function() {});

      iframe.current.addEventListener('mouseup', () => {
        console.log('IFRAME CLICKED');
        handleVideoClick();
      });
    },
    [video]
    //[]
  );
  */

  function gotoNextVideo(e) {
    e.stopPropagation();
    // if last video, goto first
    let i = pageVideoIndex >= pageVideoCount - 1 ? 0 : pageVideoIndex + 1;
    history.push(`/${video.parent}/${parentPageVideos[i]}`);
  }

  function gotoPrevVideo(e) {
    e.stopPropagation();
    let i = pageVideoIndex <= 0 ? pageVideoCount - 1 : pageVideoIndex - 1;
    history.push(`/${video.parent}/${parentPageVideos[i]}`);
  }

  return (
    <Container className="container">
      <Header dark />
      <Content>
        <div className="columns">
          <div className="column col-12">
            <PageTitle
              dangerouslySetInnerHTML={{ __html: video.video_title }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column col-12">
            <VideoWrapper>
              {pageVideoCount > 1 && (
                <ArrowsContainer>
                  <Arrow onClick={gotoPrevVideo} left dark />
                  <Arrow onClick={gotoNextVideo} right dark />
                </ArrowsContainer>
              )}

              <VideoPoster poster={video.poster} playing={playing.toString()}>
                <div />
                <iframe
                  src={`https://player.vimeo.com/video/320274211`}
                  title="videoPlayer"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  ref={iframe}
                />

                <div>
                  <div>Play</div>
                </div>
              </VideoPoster>
            </VideoWrapper>
          </div>
        </div>
        <div className="columns">
          <TextCol className="column col-6 col-sm-12">
            {video.column_1_title && (
              <TextTitle>
                {video.column_1_title}
                <br />—
              </TextTitle>
            )}
            {video.column_1_content && (
              <div
                dangerouslySetInnerHTML={{ __html: video.column_1_content }}
              />
            )}
          </TextCol>

          <TextCol className="column col-6 col-sm-12">
            {video.column_2_title && (
              <TextTitle>
                {video.column_2_title}
                <br />—
              </TextTitle>
            )}
            {video.column_2_content && (
              <div
                dangerouslySetInnerHTML={{ __html: video.column_2_content }}
              />
            )}
          </TextCol>
        </div>
      </Content>
      <Footer dark />
    </Container>
  );
}

export default VideoPage;
