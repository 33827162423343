import React, { useState, useLayoutEffect, useEffect, useContext } from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';
// import posed, { PoseGroup } from 'react-pose';

// Comonents
import Menu from 'components/Menu';
import ScrollBarAdapter from 'components/ScrollBarAdapter';
import Routes from 'routes.js';

// contextx
import { UiContext } from 'contexts/UiContext';

const PageContainer = styled.div`
  /* height: 100%; */
  /* width: 100%; */
  /* flex: 1; */
  display: flex;
  position: absolute;
  z-index: ${p => p.theme.z.body};
  min-height: 100%;
  opacity: ${p => (p.menuOpen ? '0' : '1')};
  transition: opacity 0s;
  transition-delay: ${p => (p.menuOpen ? '0.5s' : '0s')};
  overflow-x: hidden;
  left: 0;
  right: ${p => -p.scrollBarWidth || 0}px;
  width: ${p => `calc(100%) + ${p.scrollBarWidt || 0}px`};
  background-color: ${p => p.theme.colors.light};
`;

function App() {
  const { location } = useReactRouter();
  const [ui] = useContext(UiContext);

  // when scrollbar is visible, we want to move the body AND menu
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  /*
  useLayoutEffect(
    () => {
      console.log('check scrollbar?');
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      // document.body.style.marginRight = -scrollBarWidth + 'px';
      setScrollBarWidth(scrollBarWidth);
    },
    [location.pathname]
  );
  */

  useLayoutEffect(() => {
    function handleResize() {
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      // document.body.style.marginRight = -scrollBarWidth + 'px';
      setScrollBarWidth(scrollBarWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // scroll to top, when page changes
  useEffect(
    () => {
      window.scrollTo(0, 0);
      // we close menu when a Link in menu is clicked.
      // this way it still works if we click the same link we are one!
      //uiDispatch({ type: 'hideMenu' });
    },
    [location.pathname]
  );

  return (
    <>
      <ScrollBarAdapter />
      <Menu scrollBarWidth={scrollBarWidth} />
      <PageContainer menuOpen={ui.menuOpen} scrollBarWidth={scrollBarWidth}>
        <Routes />
      </PageContainer>
    </>
  );
}

export default App;
