import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Bottom = styled.div`
  padding-bottom: 4.375rem; // 70px
  padding-bottom: 3.75rem; // 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.colors.light};
  font-size: 1.125rem; /* 18px */
  > div:nth-child(1) {
    flex: 1;
    font-weight: 200;
    a {
      color: ${p => p.theme.colors.light};
      text-decoration: none;
      &:hover {
        > span {
          opacity: 0.6;
        }
      }
    }
  }
  color: ${p => (p.dark ? p.theme.colors.dark : p.theme.colors.light)};
  svg > path {
    fill: ${p => (p.dark ? p.theme.colors.dark : p.theme.colors.light)};
  }
`;

const VimeoLogo = styled(({ className }) => (
  <svg className={className} width="24" height="20" viewBox="0 0 24 20">
    <path d="M18.4,13.87Q13.68,20,10.42,20,8.4,20,7,16.27L5.13,9.42q-1-3.73-2.23-3.73A7.15,7.15,0,0,0,1.09,6.78L0,5.38q1.71-1.5,3.37-3Q5.66.4,6.8.29,9.5,0,10.12,4q.67,4.26.93,5.3.78,3.54,1.71,3.53.73,0,2.18-2.29A9,9,0,0,0,16.51,7q.21-2-1.56-2a4.33,4.33,0,0,0-1.71.38Q14.95-.15,19.75,0q3.56.1,3.36,4.62Q23,8,18.4,13.87Z" />
  </svg>
))`
  cursor: pointer;
  width: 24px;
  height: 20px;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.6;
  }
  path {
    fill: ${p => p.theme.colors.light};
  }
`;

function Footer({ dark = false, slide = false }) {
  return (
    <Bottom dark={dark}>
      <div>
        {slide && (
          <Link to={`${slide.parent}/${slide.uri}`}>
            <span dangerouslySetInnerHTML={{ __html: slide.title }} />
          </Link>
        )}
      </div>
      <div>
        <a
          href="https://vimeo.com/user8147504"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VimeoLogo />
        </a>
      </div>
    </Bottom>
  );
}

export default Footer;
